/* FOR DEBUGGING PAGE OVERFLOW ISSUES: */
.redesign.debugHighlighting * {
  outline: solid rgba(139, 50, 255, 0.25) 1px !important;
}
.redesign .debug-only {
  display: none;
}
.redesign.debugHighlighting .debug-only {
  display: unset;
}

/* FOR VISUAL DEBUGGING */
.zz-display-none {
  display: none;
}

.debug-box {
  opacity: 85%;
  background-color: #a7ffe0;
  padding: 16px 12px;
  border-radius: 8px;
  margin-bottom: 16px;
  font-size: 0.95rem;
  color: #333;
  /* border-left: 6px solid var(--evergreen); */
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.08); */
  /* transition: background-color 0.3s ease, box-shadow 0.2s ease; */
}

/* .debug-box:hover {
  background-color: #e6f4e2;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.12);
} */
