.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-align-center {
  align-items: center;
}

/* ========================= */

.text-center {
  text-align: center;
}


.raised-box {
  max-width: 700px;
  margin: 16px;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  background-color: var(--pure-white);
}
.raised-box.primary {
  background-color: var(--evergreen);
}

.logo-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 32px;
  width: 100vw;
}

.logo-bar .logo {
  width: 128px;
  height: 128px;
  padding: 0px;
  border-radius: 24px;
}
.logo-bar .logo img {
  width: 100%;
  height: 100%;
}

/* ========== REPORT TEMP ============ */
/* as we develop the report, these will get their own file */

.report-grid {
  display: grid;
  grid-template-columns: [labels] 3fr [values] 4fr [annotations] 3fr [end];
  grid-template-areas: "header header header" "main main main";
  column-gap: 10px;
  row-gap: 5px;
}
.report-value-singular,
.report-value-multi,
.report-entry-text {
  grid-column: values / end;
}
.report-value-multi p {
  margin: 6px 0px 6px 0px;
}
.report-value-multi p:first-of-type {
  margin-top: 0;
}
.report-label {
  grid-column: labels;
  font-weight: bold;
}
.report-entry-full {
  grid-column: labels / end;
}
.report-value-measurement .unit-separator {
  opacity: 0.3;
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.nonconforming-list {
  font-style: italic;
  margin: 0;
  padding: 0;
}
.nonconforming-list li {
  list-style: none;
}
.nonconforming-list .ncr-choice {
  opacity: 0.75;
}
.user-defined {
  padding: 2px;
  border: 1px dotted lightgrey;
}
.report-value-singular .user-defined {
  display: inline-block;
  width: 100%;
}