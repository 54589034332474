/* Color Palette */

:root {
  /* ==== base named colors ==== */
  --evergreen: hsl(155, 76%, 50%); /* #1fe191 */
  --softened-evergreen: hsl(155, 76%, 90%); /* #d2f9e9 */
  --middle-evergreen: hsl(155, 74%, 41%); /* #1bb575 */
  --dark-evergreen: hsl(155, 72%, 31%); /* #168959 */
  --pure-white: hsl(0, 0%, 100%); /* #ffffff */
  --abyss: hsl(0, 0%, 13%); /* #222222 */
  --salmon-red: hsl(11, 72%, 64%); /* #e57962 */
  --sky-blue: hsl(184, 76%, 50%); /* #1fd5e1 */

  --semitranslucent-white: hsla(0, 0%, 100%, 0.8);
  --translucent-white: hsla(0, 0%, 100%, 0.5);

  /* ==== colors labeled by roles ==== */

  /* brand */
  --primary-color: var(--evergreen);

  /* fonts */
  --dark-font: var(--abyss);
  --light-font: var(--pure-white);

  /* buttons */
  --lightest-button-color: var(--pure-white);
  --base-button-color: var(--evergreen);
  --transition-button-color: var(--middle-evergreen);
  --darkest-button-color: var(--dark-evergreen);

  /* effects */
  --shadow-bluish: hsla(199, 18%, 40%, 0.2);
}
