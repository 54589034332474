/* Main structure styles */
.dashboard-container {
  display: flex;
  background-color: #f0f4f8;
  position: relative;
  overflow-y: auto;
}

.sidebar {
  width: 325px;
  background-color: #fff;
  color: #fff;
  border-right: 1px solid #dee2e6;
  box-shadow: 2px 0 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
  transition: transform 0.3s ease;
  transform: translateX(0);
}

/* Toggle button for mobile */
.toggle-button {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: #fff;
  color: #777;
  border: none;
  font-size: 24px;
  padding: 10px;
  cursor: pointer;
  display: none;
}

.console-navbar {
  padding: 20px 55px 20px 20px;
}

.console-navbar ul {
  list-style: none;
  padding: 0;
}

.console-navbar li {
  margin: 10px 0;
}

.console-navbar a {
  text-decoration: none;
  font-size: 16px;
  color: #333;
  display: block;
  padding: 10px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.console-navbar a:hover {
  background-color: #e2e6ea;
}

.logo-container {
  text-align: left;
  margin-bottom: 10px;
  padding: 20px;
  border-bottom: 1.5px solid #e0e0e0;
  color: #333;
}

.logo {
  max-width: 60%;
  height: auto;
}

.nav-category {
  font-size: 14px;
  font-weight: bold;
  color: #495057;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.divider {
  margin: 15px 0;
  border: none;
  border-top: 1px solid#bbbbbb;
}

.content {
  flex-grow: 1;
  padding: 40px;
  background-color: #fff;
  /* box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1); */
  color: #333;
  overflow-y: auto;
}

.content-wrapper {
  margin-left: 2em;
  max-width: 1450px;
}

.page-title .main-column {
  font-size: 32px;
}
.page-title {
  margin-top: 0px;
  margin-bottom: 0px;
}

.content p {
  font-size: 18px;
  color: #333;
  margin-top: 0px;
}

/* List item styling */
.active-questionnaires {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}
.active-questionnaires,
.debug-box {
  list-style-type: none;
  padding: 16px;
  margin-bottom: 1.5em;
  transition: background-color 0.3s ease;
}

.active-questionnaires:hover {
  background-color: #f9f9f9;
}

/* Style for nested li (variants of questionnaires) */
.select-list-item,
.debug-box li {
  list-style-type: none;
  padding: 12px;
  margin-bottom: 12px;
  border: 1px solid #888;
  border-radius: 8px;
  /* background-color: #fff; */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08); */
}

.list-questionnaire-details {
  list-style-type: none;
  padding: 12px;
}

.content .btn {
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 0.85rem;
  cursor: pointer;
  border: none;
}

.content .btn-warning {
  background-color: var(--evergreen);
  padding: 12px;
  margin-left: 12px;
  color: #fff;
  transition: background-color 0.2s ease;
}

.content .btn-warning:hover {
  background-color: #4e8d6c;
}

.content small {
  display: block;
  margin-top: 12px;
  font-size: 0.85rem;
  color: #666;
}

.content strong {
  color: #333;
}

.content .section-heading {
  margin: 0;
  color: #f50057;
}

/* Ensure proper spacing for nested lists */
.content ul {
  padding-left: 1.5em;
}

.messages-wrapper {
  margin-top: 20px;
  border-top: 1px solid #888;
  border-right: 2px solid #888;
  border-left: 2px solid #888;
  border-bottom: 1px solid #888;
  padding: 20px;
  background-color: #f0f4f8;
}

.highlight-box {
  background-color: #ffecb3;
  padding: 16px;
  border-radius: 10px;
  font-size: 1rem;
  grid-column: 1 / -1;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-left: 5px solid #f5a623;
  max-width: 950px;
}

.highlight-error-message {
  background-color: #f99384;
  padding: 16px;
  border-radius: 10px;
  font-size: 1rem;
  grid-column: 1 / -1;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-left: 5px solid #f53d23;
  max-width: 950px;
}

.note {
  width: 100%;
  margin: 0 auto;
  padding: 12px 16px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  font-size: 0.95rem;
  line-height: 1.5;
}

.note strong {
  color: #f5a623;
}

kbd {
  background-color: #f1f1f1;
  border-radius: 4px;
  padding: 2px 6px;
  font-size: 0.9rem;
  font-weight: bold;
  color: #333;
  border: 1px solid #ccc;
}

@media (max-width: 950px) {
  .highlight-box {
    grid-column: 1;
    max-width: 100%;
  }
}

@media (max-width: 600px) {
  .content {
    padding: 15px;
  }

  .highlight-box {
    padding: 12px;
  }
}

@media (max-width: 768px) {
  /* Sidebar hidden initially */
  .sidebar {
    position: fixed;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 1000;
    overflow-y: auto;
    transform: translateX(-100%);
  }

  .sidebar.open {
    transform: translateX(0);
  }

  /* Show toggle button on small screens */
  .toggle-button {
    display: block;
    z-index: 1100;
  }

  .col {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
}

.overflow-x {
  overflow-x: auto;
}

.questionnaire-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 16px;
  text-align: left;
}

.questionnaire-table thead tr {
  background-color: #f8f9fa;
}

.questionnaire-table th,
.questionnaire-table td {
  padding: 12px 15px;
  border-bottom: 1px solid #dee2e6;
}

.questionnaire-table tbody tr:hover {
  background-color: #f1f1f1;
}

.select-list-item {
  padding: 10px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.variant-list {
  margin-top: 10px;
  padding-left: 20px;
}

.list-questionnaire-details {
  margin-top: 10px;
  font-size: 14px;
}

.badge {
  display: inline-block;
  padding: 0.5em 0.75em;
  font-size: 0.9em;
  border-radius: 0.25rem;
}

.badge-dark {
  background-color: #343a40;
  color: #fff;
}

.badge-light {
  background-color: #f8f9fa;
  color: #343a40;
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

/* --------------- Loading Spinner --------------- */
.loading-spinner {
  color: #fff;
  font-size: 10px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.3s infinite linear;
  transform: translateZ(0);
  margin: 0 auto;
}

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em #f50057, 2em -2em 0 0em #f50057,
      3em 0 0 -1em #f50057, 2em 2em 0 -1em #f50057, 0 3em 0 -1em #f50057,
      -2em 2em 0 -1em #f50057, -3em 0 0 -1em #f50057, -2em -2em 0 0 #f50057;
  }
  12.5% {
    box-shadow: 0 -3em 0 0 #f50057, 2em -2em 0 0.2em #f50057, 3em 0 0 0 #f50057,
      2em 2em 0 -1em #f50057, 0 3em 0 -1em #f50057, -2em 2em 0 -1em #f50057,
      -3em 0 0 -1em #f50057, -2em -2em 0 -1em #f50057;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em #f50057, 2em -2em 0 0 #f50057,
      3em 0 0 0.2em #f50057, 2em 2em 0 0 #f50057, 0 3em 0 -1em #f50057,
      -2em 2em 0 -1em #f50057, -3em 0 0 -1em #f50057, -2em -2em 0 -1em #f50057;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em #f50057, 2em -2em 0 -1em #f50057,
      3em 0 0 0 #f50057, 2em 2em 0 0.2em #f50057, 0 3em 0 0 #f50057,
      -2em 2em 0 -1em #f50057, -3em 0 0 -1em #f50057, -2em -2em 0 -1em #f50057;
  }
  50% {
    box-shadow: 0 -3em 0 -1em #f50057, 2em -2em 0 -1em #f50057,
      3em 0 0 -1em #f50057, 2em 2em 0 0 #f50057, 0 3em 0 0.2em #f50057,
      -2em 2em 0 0 #f50057, -3em 0 0 -1em #f50057, -2em -2em 0 -1em #f50057;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em #f50057, 2em -2em 0 -1em #f50057,
      3em 0 0 -1em #f50057, 2em 2em 0 -1em #f50057, 0 3em 0 0 #f50057,
      -2em 2em 0 0.2em #f50057, -3em 0 0 0 #f50057, -2em -2em 0 -1em #f50057;
  }
  75% {
    box-shadow: 0em -3em 0 -1em #f50057, 2em -2em 0 -1em #f50057,
      3em 0em 0 -1em #f50057, 2em 2em 0 -1em #f50057, 0 3em 0 -1em #f50057,
      -2em 2em 0 0 #f50057, -3em 0em 0 0.2em #f50057, -2em -2em 0 0 #f50057;
  }
  87.5% {
    box-shadow: 0em -3em 0 0 #f50057, 2em -2em 0 -1em #f50057,
      3em 0 0 -1em #f50057, 2em 2em 0 -1em #f50057, 0 3em 0 -1em #f50057,
      -2em 2em 0 0 #f50057, -3em 0em 0 0 #f50057, -2em -2em 0 0.2em #f50057;
  }
}
/* --------------- Loading Spinner Ends --------------- */
