html, body {
  overscroll-behavior: none;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col {
  flex: 1;
  padding-right: 15px;
  padding-left: 15px;
}

/* Column sizes based on screen size */
.col-1 {
  flex: 0 0 8.33%;
  max-width: 8.33%;
}
.col-2 {
  flex: 0 0 16.66%;
  max-width: 16.66%;
}
.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  flex: 0 0 33.33%;
  max-width: 33.33%;
}
.col-5 {
  flex: 0 0 41.66%;
  max-width: 41.66%;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  flex: 0 0 58.33%;
  max-width: 58.33%;
}
.col-8 {
  flex: 0 0 66.66%;
  max-width: 66.66%;
}
.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  flex: 0 0 83.33%;
  max-width: 83.33%;
}
.col-11 {
  flex: 0 0 91.66%;
  max-width: 91.66%;
}
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

/* Clearfix for floated columns, if needed */
.row::after {
  content: "";
  display: table;
  clear: both;
}

hr {
  margin: 20px 0;
}

.loader-dots-fade {
  margin: auto;
  width: 60px;
  aspect-ratio: 4;
  --_g: no-repeat radial-gradient(circle closest-side, currentColor 90%,#0000);
  background: 
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 100%;
  animation: l7 1s infinite linear;
}
@keyframes l7 {
    33%{background-size:calc(100%/3) 0%  ,calc(100%/3) 100%,calc(100%/3) 100%}
    50%{background-size:calc(100%/3) 100%,calc(100%/3) 0%  ,calc(100%/3) 100%}
    66%{background-size:calc(100%/3) 100%,calc(100%/3) 100%,calc(100%/3) 0%  }
}