#highlight_base .highlight-on,
#highlight_base .highlight-on path,
#highlight_base .highlight-on polyline,
#highlight_base .highlight-on g,
#highlight_base .highlight-on rect {
  /* display: unset !important; */
  opacity: 0.85 !important;
}

.card.selected {
  background: var(--evergreen);
}

.anatomical-model-legend {
  padding: 10px;
}
.anatomical-model-legend .card {
  margin-bottom: 20px;
  height: 66px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.anatomical-model-legend .card .card-body {
  padding: 10px 20px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  font-size: 20px;
  font-weight: bold;
  /* text-overflow: ellipsis; */
}

/* 
#anatomical-root svg {
  outline: greenyellow 1px solid;
} 
*/

svg#base_art rect[stroke="#FF0000"i] {
  display: none;
}

/*
Safari cannot handle painting foreignObjects correctly when some CSS properties
are used -- presumably this is any property that causes the creation of a new
stacking context -- and instead moves their content to an incorrect position.
Worse yet, this often causes the click handler to fail. Thus we must turn off
transforms and replace the opacity call with half-transparent colors.

https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_positioned_layout/Understanding_z-index/Stacking_context
*/
foreignObject .card {
  transition: unset;
}
foreignObject .card:hover {
  transform: unset;
}
.see-through-unselected .card:not(.selected):not(.has-active-descendant) {
  /* opacity: 0.5; */
  background: rgba(256, 256, 256, 0.5);
  color: rgba(0, 0, 0, 0.5);
}

.card.has-active-descendant {
  position: relative;
}
.card.has-active-descendant:after {
  content: "";
  background: radial-gradient(
    circle at center,
    white 33%,
    var(--evergreen) 40%,
    var(--evergreen) 65%,
    transparent 72%
  );
  top: -6px;
  position: absolute;
  width: 16px;
  height: 16px;
  right: -6px;
}
