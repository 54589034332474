/* once redesign becomes the norm, remove class prefix AND organize into separate files */

/* 
CONFIRM NOT IN USE
.redesign .container {
  max-width: 850px !important;
} */

.full-height {
  height: 100vh;
  height: 100dvh;
}
.force-full-height {
  min-height: 100vh;
  min-height: 100dvh;
}

.redesign .centered-flex-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  /* min-width: 320px; */
}

.centered-flex-container > div,
.centered-flex-container > header {
  width: 100%;
}

.redesign .chrome {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.redesign .main-column {
  padding: 20px;
}

.redesign .title-img {
  height: 45vh;
  height: 45dvh;
  max-height: calc(min(550px, 95vw));
  margin: 20px 0;
}
.redesign.page-success .main-column {
  padding-top: 0;
}
.redesign.page-success .title-img {
  margin: 0;
  height: 25vh;
  height: 25dvh;
}
.redesign.page-success h1 {
  margin: 0;
}

.redesign .pad-anatomical {
  /* padding: 50px 0px 0px 0px; */
}
.pad-anatomical > .main-column {
  width: calc(100dvw - 144px) !important;
  max-width: calc(100dvw - 144px);
}
.redesign.has-backdrop .pad-for-header {
  padding: max(25dvh, 125px) 18px 0px 18px;
}
.redesign:not(.has-backdrop) .pad-for-header {
  /* padding: max(10dvh, 80px) 18px 0px 18px; */
  padding: 50px 18px 0px 18px;
}

.redesign .question {
  font-style: normal;
  color: #000000;
  margin-top: 16px;
  /* padding: 8px;  */
  margin-bottom: 16px;
  font-weight: 600;
}

#anatomical-root {
  width: 100vw;
  width: 100dvw;
  padding-top: 20px;
}

.redesign .question-scroller {
  flex-grow: 1;
  overflow-y: auto;
  /* padding: 16px 0; */
  mask-image: linear-gradient(to bottom, transparent, black 16px);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.redesign .question-with-inner-scroll {
  height: 100vh;
  height: 100dvh;
}
.redesign.page-question-modal .question-with-inner-scroll {
  height: auto;
}

.redesign .question-subtext {
  font-size: 1.5rem;
}

.redesign .highlight-interactive-text {
  background: #f8ee00;
  border-radius: 4px;
  padding: 2px;
  font-weight: 700;
}

.redesign .bold-interactive-text {
  cursor: pointer;
  color: var(--evergreen) !important;
}

.paragraph {
  max-width: 85%;
}

.redesign .float-bottom-right {
  position: absolute;
  bottom: env(safe-area-inset-bottom, 0px);
  top: calc(100dvh - 80px);
  margin-bottom: 20px;
  right: 20px;
  z-index: 100;
}

.redesign .bigger p {
  font-size: 24px;
}
.redesign .resource-dialog {
  padding: 1em;
}

.redesign .contextual-label {
  color: var(--salmon-red) !important;
  white-space: nowrap;
}
.redesign .bold-interactive-text,
.redesign .contextual-label {
  margin: 0.5em 0;
  font-weight: 700;
}

/* anything using .*-img-card-container is unused
.redesign .rect-img-card-container
.sqr-img-card-container {
  margin-left: auto;
  margin-right: auto;
} */

/* anything using .rect-img-card-container is unused
.redesign .rect-img-card-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 768px;
} */

.redesign .rect-img-card {
  position: relative;
  border-radius: 8px;
  margin: 8px 0px;
  background-color: white;
  cursor: pointer;
  display: flex;
  box-shadow: 0px 0px 16px rgba(84, 110, 122, 0.25);
  color: #000000;
  transition: color 0.3s 0.15s ease-out;
}

.rect-img-card.highlight {
  box-shadow: 0px 0px 16px 2px var(--evergreen);
}

/* anything using .sqr-img-card-container is unused
.redesign .sqr-img-card-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  min-height: 135px;
  max-height: 314px;
}
*/

.redesign .sqr-img-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center !important;
  /* width: 135px;
  height: 135px; */
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 0px 16px rgba(84, 110, 122, 0.25);
  cursor: pointer;
}

/* text transition color => transition: all .6s ease-out; */

/* anything using image-wrapper is unused
.redesign .rect-img-card .image-wrapper img {
  border-bottom-left-radius: 8px;
}
.redesign .sqr-img-card .image-wrapper img {
  border-top-right-radius: 8px;
} */

.redesign .exclusion-btn {
  gap: 20px;
  padding: 0 20px;
}

.card .glyph-as-image {
  /* this is just visual approximation, we need to figure out how to
     do the line-height correctly for diff screen sizes TODO! */
  font-size: 130px;
  padding: 10px 25px;
}

.page-title .text-center,
.page-kiosk_consent .text-center,
.page-kiosk_start .text-center {
  padding: 0 16px;
}
.page-title p.text-center {
  font-size: 1.5rem;
}
.page-kiosk_consent a {
  text-decoration: underline;
}
.page-success {
  overflow-y: scroll;
}

/* anything using image-wrapper is unused
.redesign .sqr-img-card .image-wrapper {
  width: 100%;
}
 .redesign .image-wrapper img {
  border-top-left-radius: 8px;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 1 / 1;
} */

.redesign .selected .card-art img[src="/images/symbol_green_o.png"] {
  filter: saturate(0.1) brightness(1.5);
}

.redesign .text-wrapper {
  padding-left: 3px;
  /* width: 65%; */
  margin-bottom: 2rem;
}

.redesign .text-wrapper p {
  margin: 0;
  margin-top: 12px;
  font-weight: 700;
  /* text-align: left; */
  /* font-size: 14px; */
}
.redesign .text-wrapper p.no-subtext {
  margin-top: 22px; /* TODO: use flexbox instead of this approach */
}

.redesign .rect-img-card .text-wrapper {
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 12px;
}
.redesign .rect-img-card .text-wrapper p {
  margin-top: 0;
}

.redesign .choice-subtext {
  margin-top: 0 !important;
  font-weight: 400 !important;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.redesign .choice-subtext.expanded {
  -webkit-line-clamp: 20;
}

/* .icon-wrapper {
  padding: 8px;
  width: 30px;
  display: flex;
  align-items: start;
  justify-content: center;
} */

.redesign .icon-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  margin: 6px 6px 0 0;
  padding: 8px;
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
}

.redesign .text-option {
  margin: 8px 12px;
  color: var(--evergreen);
  font-weight: 700;
  font-size: 14px;
}

.redesign textarea,
.redesign input {
  /* min-width: 280px; */
  background: var(--pure-white);
  border: 1px solid #aaaaaa;
  border-radius: 8px;
  padding: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins";
}

.redesign textarea {
  height: 200px;
  resize: none;
}

.redesign input[type="number"]::-webkit-inner-spin-button {
  opacity: 1;
}

.redesign textarea:focus,
.redesign input:focus {
  outline: none;
  caret-color: var(--evergreen);
  border: 1px solid var(--evergreen);
}

/* anything using image-wrapper is unused
.redesign .rect-img-card .image-wrapper.no-image {
  max-width: 3em !important;
} */

/* todo: fix the min-height max-height issue on the cards - same with widths */
/* Styles for viewport width below 375px */
/* note that our smallest target size is (min-width: 320px) */
@media screen and (max-width: 375px) {
  .redesign .question,
  .highlight-interactive-text,
  .bold-interactive-text,
  .contextual-label {
    font-size: 1.25rem;
    line-height: 24px;
  }
  .redesign .question-subtext {
    font-size: 1rem;
  }
  .redesign .main-column {
    padding: 5px;
  }

  /* .redesign .rect-img-card-container, */
  .redesign .main-column,
  .redesign .exclusion-btn
  /* .sqr-img-card-container  */ {
    /* max-width: 335px;
    min-width: 280px; */
    width: 100%;
  }
  .redesign .stack-height {
    height: 100px;
  }
  /* anything using image-wrapper is unused
  .redesign .rect-img-card .image-wrapper {
    min-height: 100px;
    width: 100px;
  } */
  .redesign .icon-wrapper,
  .icon-wrapper img {
    width: 32px;
  }
  .redesign .text-wrapper p,
  .choice-subtext {
    font-size: 14px !important;
    line-height: 21px;
  }

  .redesign textarea,
  .redesign input {
    width: 100%;
  }

  .card .glyph-as-image {
    font-size: 64px;
  }

  .redesign .exclusion-btn {
    gap: 8px;
  }

  .redesign.has-backdrop .pad-for-header {
    padding: max(25dvh, 125px) 8px 18px 8px;
  }
  .redesign:not(.has-backdrop) .pad-for-header {
    padding: max(10dvh, 80px) 8px 18px 8px;
  }
}

/* Styles for viewport width 376px - 743px */
@media screen and (min-width: 376px) and (max-width: 743px) {
  .redesign .question,
  .highlight-interactive-text,
  .bold-interactive-text,
  .contextual-label {
    font-size: 1.25rem;
    line-height: 30px;
  }
  .redesign .question-subtext {
    font-size: 1rem;
  }
  /* .redesign .rect-img-card-container, */
  .redesign .main-column,
  .redesign .exclusion-btn,
  /* .sqr-img-card-container, */
  .redesign textarea,
  .redesign input {
    width: 100%;
    max-width: 620px;
  }
  /* anything using .sqr-img-card-container is unused
  .redesign .sqr-img-card-container {
    height: 100%;
    max-height: 235px;
  }
    */
  .redesign .stack-height {
    height: 108px;
  }
  /* anything using image-wrapper is unused
  .redesign .rect-img-card .image-wrapper {
    min-height: 108px;
    width: 108px;
  } */
  .redesign .icon-wrapper,
  .icon-wrapper img {
    width: 32px;
  }
  .redesign .text-wrapper p,
  .choice-subtext {
    font-size: 16px !important;
    line-height: 24px;
  }
  .card .glyph-as-image {
    font-size: 80px;
  }
}

/* Styles for viewport width 744px - 1023px */
@media screen and (min-width: 744px) and (max-width: 1023px) {
  .redesign .question,
  .highlight-interactive-text,
  .bold-interactive-text,
  .contextual-label {
    font-size: 1.5rem;
    line-height: 36px;
  }
  .redesign .question-subtext {
    font-size: 1.25rem;
  }
  /* .redesign .rect-img-card-container, */
  .redesign .main-column,
  .redesign .exclusion-btn,
  /* .sqr-img-card-container, */
  .redesign textarea,
  .redesign input {
    width: 100%;
    max-width: 648px;
  }
  /* anything using .sqr-img-card-container is unused
  .redesign .sqr-img-card-container {
    height: 314px;
  } */
  .redesign .stack-height {
    height: 150px;
  }
  /* anything using image-wrapper is unused
  .redesign .rect-img-card .image-wrapper {
    min-height: 150px;
    width: 150px;
  } */
  .redesign .icon-wrapper,
  .icon-wrapper img {
    width: 42px;
  }
  .redesign .text-wrapper p,
  .choice-subtext {
    font-size: 20px;
    line-height: 30px !important;
  }
  .card .glyph-as-image {
    font-size: 112px;
  }
}

/* Styles for viewport width 1024px or more */
@media screen and (min-width: 1024px) {
  .redesign .question,
  .highlight-interactive-text,
  .bold-interactive-text,
  .contextual-label {
    font-size: 2rem;
    line-height: 48px;
  }

  /* .redesign .rect-img-card-container, */
  .redesign .main-column,
  .redesign .exclusion-btn,
  /* .sqr-img-card-container, */
  .redesign textarea,
  .redesign input {
    width: 100%;
    max-width: 768px;
  }
  /* anything using .sqr-img-card-container is unused
   .redesign .sqr-img-card-container {
    height: 314px;
  } */
  .redesign .stack-height {
    height: 165px;
  }
  /* anything using image-wrapper is unused
  .redesign .rect-img-card .image-wrapper {
    min-height: 165px;
    width: 165px;
  } */

  .redesign .icon-wrapper,
  .icon-wrapper img {
    width: 42px;
  }
  .redesign .text-wrapper p,
  .choice-subtext {
    font-size: 1.5rem;
    line-height: 36px !important;
  }
}

/* todo: Text color should change with transition */
/* todo: add active color to chrome icons */

/* .selected {
  color: #fff; 
  background: linear-gradient(to left, #fff 50%, var(--evergreen) 50%);
  background-size: 200% 100%;
  background-position: left bottom;
  transition: all 0.5s ease-out;
} */

.redesign .selected {
  /* color: #fff; */
  background: linear-gradient(to left, #fff 50%, var(--evergreen) 50%);
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: animated-fill 0.1s ease-out 0.3s forwards;
  /* transition: all 0.3s ease-out; */
}

/* ----------------------- Fill ANIMATION ----------------------- */

.redesign .fill-selected {
  color: #fff !important;
  background-color: var(--evergreen) !important;
}

@keyframes animated-fill {
  from {
    background-position: 100% 0;
  }
  to {
    background-position: 0 0;
  }
}

/* ----------------------- PULSE ANIMATION ----------------------- */

.redesign .selected.pulsing {
  animation: animated-fill 0.1s ease-out 0.3s forwards,
    pulse-animation 1.5s infinite;
}

.pulsing {
  animation: pulse-animation 1.5s infinite;
}
.pulsing:not(.card) {
  border-radius: 8px;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0);
  }
  5% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.25);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
}

/* .redesign .selected .icon-wrapper {
  filter: brightness(0) invert(1);
} */

/* --------- FOR WALKTHROUGH DEMO ONLY --------- */
.redesign .emphasize-container {
  border: 2px solid var(--evergreen) !important;
  border-radius: 7px;
  color: #000000 !important;
}

.redesign .emphasize-container .bold-interactive-text,
.redesign .emphasize-container .contextual-label {
  color: #ffffff !important;
}

.redesign .emphasize-container .icon-wrapper {
  filter: brightness(1) invert(0);
}

/* ----------------------- POP-UP MODAL ----------------------- */

.redesign .popup {
  display: inline-block;
  position: relative;
  z-index: 1;
  padding: 20px;
}

.redesign .popup-message {
  width: 450px;
  height: 125px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 20px;
  align-content: center;
}

.redesign .popup-close-btn {
  position: absolute;
  top: 12px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
  cursor: pointer;
}
.redesign .popup-close-btn:hover {
  color: red;
}

/* ----------------------- START of SLIDER STYLES ----------------------- */
.slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slider {
  width: 300px;
  -webkit-appearance: none;
  height: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  cursor: pointer;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: var(--evergreen);
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: var(--evergreen);
  cursor: pointer;
}

.slider-labels {
  display: flex;
  justify-content: space-between;
  width: 300px;
  font-size: 12px;
  color: #888;
}

.value-marker {
  position: relative;
  /* top: -10px; */
  display: relative;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  font-size: 12px;
  pointer-events: none;
}

.tear-drop {
  position: relative;
  width: 20px;
  height: 20px;
  background-color: var(--evergreen);
  border-radius: 50% 50% 50% 0;
  transform: rotate(-45deg);
}

.value-text {
  position: absolute;
  color: #ffffff;
  top: 0px;
  width: 100%;
  text-align: center;
  /* transform: rotate(360deg); */
}

/* ----------------------- END of SLIDER STYLES ----------------------- */

/* USED IN SLIDER AND RANGE PICKER */
.image-container {
  margin-bottom: 20px;
}

/* Form Temp */

/* WHAT IS THIS FOR? */
:root {
  --number-of-options: 5;
}

/* ----------------------- START of RANGE PICKER ----------------------- */

#range-picker-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#range-picker {
  width: 90%;
  max-width: 500px;
}

#amount-slider {
  display: flex;
  flex-direction: row;
  align-content: stretch;
  position: relative;
  width: 100%;
  height: 50px;
  user-select: none;
}

#amount-slider::before {
  content: " ";
  position: absolute;
  height: 2px;
  width: 100%;
  width: calc(100% * (var(--number-of-options) - 1) / var(--number-of-options));
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #000;
}

#amount-slider input,
#amount-slider label {
  flex: 1;
  user-select: none;
  cursor: pointer;
}

#amount-slider label {
  display: inline-block;
  position: relative;
  width: 20%;
  height: 100%;
  user-select: none;
}

#amount-slider label::before {
  content: attr(data-amount);
  position: absolute;
  left: 50%;
  padding-top: 10px;
  transform: translate(-50%, 45px);
  font-size: 14px;
  letter-spacing: 0.4px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 0.85;
  transition: all 0.15s ease-in-out;
}

#amount-slider label::after {
  content: " ";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  border: 2px solid #000;
  background: #fff;
  border-radius: 50%;
  pointer-events: none;
  user-select: none;
  z-index: 1;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
}

#amount-slider label:hover::after {
  transform: translate(-50%, -50%) scale(1.25);
}

#amount-slider input {
  display: none;
}

#amount-slider input:checked + label::before {
  font-weight: 800;
  opacity: 1;
}

#amount-slider input:checked + label::after {
  border-width: 4px;
  transform: translate(-50%, -50%) scale(0.75);
}

#amount-slider input:checked ~ #amount-pos {
  opacity: 1;
}

/* #amount-slider input:nth-child(odd):checked ~ #amount-pos {
  left: calc((var(--i) * 20%) - 10%);
} */

/* #amount-slider input:nth-child(odd):checked ~ #amount-pos {
  left: calc((var(--number-of-options) / 2 - 1) * 20%);
} */

#amount-pos {
  display: block;
  position: absolute;
  top: 50%;
  width: 18px;
  height: 18px;
  background: var(--evergreen);
  border-radius: 50%;
  transition: all 0.15s ease-in-out;
  transform: translate(-50%, -50%);
  border: 2px solid #fff;
  opacity: 0;
  z-index: 2;
}

form:valid #amount-slider input + label::before {
  transform: translate(-50%, 45px) scale(0.9);
  transition: all 0.15s linear;
}

form:valid #amount-slider input:checked + label::before {
  transform: translate(-50%, 45px) scale(1.1);
  transition: all 0.15s linear;
}
/* ----------------------- END of RANGE PICKER ----------------------- */

.modal-summary-page-wrapper {
  max-width: 720px;
  margin: auto;
}

/* ----------------------- MUI Number Picker ----------------------- */
.measurement-system-select .MuiButtonBase-root {
  background: #f1f1f1;
  text-transform: none;
  color: var(--abyss);
}
.measurement-system-select .MuiButtonBase-root.Mui-selected {
  background: var(--evergreen);
  color: var(--abyss);
}
.measurement-system-select .MuiButtonBase-root.Mui-selected:hover {
  background: var(--evergreen);
}

.measurement-base {
}

.measurement-unit-input .exhaustive-entry {
  padding: 12px;
  margin: 0px;
  font-size: 16px;
}
.measurement-unit-input .exhaustive-entry.selected {
  background: var(--evergreen);
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: var(--abyss);
}

.measurement-unit-input {
  background: #fff;
  border-radius: 16px;
  padding: 16px 0px;
  box-shadow: 0px 0px 20px 0px var(--shadow-bluish);
  max-width: 100%; /* Ensures responsiveness on mobile */
  margin: 16px auto;
  position: relative;
}
.measurement-unit-input .Picker {
  display: flex;
  align-items: center;
}

.redesign .MuiPickersDay-hidden {
  opacity: 0.3;
}

.MuiPickersCalendarHeader-transitionContainer p {
  font-weight: bold;
}
.MuiPickersCalendarHeader-transitionContainer p:hover {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  margin: auto 1em;
}
.MuiPickersCalendarHeader-transitionContainer p:after {
  content: "⌄";
  position: absolute;
  bottom: 1px;
  margin-left: 10px;
  font-size: 24px;
  width: 10px;
  opacity: 0.6;
}

.dateQuestionWrapper {
  min-width: 400px;
  position: relative;
  top: -20px;
}

/* ----------------------- ???? ANIMATION ----------------------- */
@keyframes spin {
  from {
    transform: rotate(0deg);
    width: 24px;
    opacity: 1;
    margin-right: 12px;
  }
  to {
    transform: rotate(360deg);
    width: 24px;
    opacity: 1;
    margin-right: 12px;
  }
}
