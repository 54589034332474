/* button color varies depending on context / theme or background color */

button {
  cursor: pointer;
}

/* Wide Buttons */
/* button {
  border: none;
  border-radius: 8px;
  background-color: var(--lightest-button-color);
  padding: 10px 20px;
  height: 44px;
  width: 160px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  box-shadow: 0px 0px 20px 0px rgba(84, 110, 122, 0.2);
  transition-duration: 0.4s;
  cursor: pointer;
}

button:hover {
  background-color: var(--transition-button-color);
}

button:active {
  background-color: var(--darkest-button-color);
  color: var(--lightest-button-color);
} */

/* Responsiveness */
/* @media (min-width: 376px) and (max-width: 743px) {
}

@media (min-width: 744px) and (max-width: 1023px) {
  button {
    font-size: 20px;
    height: 52px;
    width: 200px;
  }
}

@media (min-width: 1024px) {
  button {
    font-size: 22px;
    height: 56px;
    width: 220px;
  }
} */

/* ------------------ BUTTONS FROM QUESTIONNAIRE.CSS ------------------ */
.redesign .btn {
  padding: 12px 32px;
  border: 1px solid rgba(84, 110, 122, 0.25);
  margin-top: 20px;
  border-radius: 0.6em;
  cursor: pointer;
}

.redesign .btn:hover {
  border: 1px solid var(--evergreen);
  background-color: var(--evergreen);
  color: var(--pure-white);
}

.redesign .btn.btn-inline {
  margin-top: 0;
  padding: 4px 12px;
}

/* CHROME BUTTONS USED FOR NAVIGATION */
.redesign .chrome-btn {
  background: var(--pure-white);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  border-radius: 24px;
  width: 48px;
  height: 48px;
}
.redesign .chrome-btn.chrome-btn-lg {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
  border-radius: 32px;
  width: 64px;
  height: 64px;
}
.redesign .chrome-btn.chrome-btn-lg.float-bottom-right {
  top: calc(100dvh - 84px);
}

.redesign .chrome-btn:hover {
  background-color: var(--evergreen);
}

.redesign .chrome-btn:hover img {
  filter: brightness(0) invert(1);
}

/* EXCLUSIONARY CHOICES BUTTON? */
.redesign .exclusion-btn {
  margin-top: 1rem;
  width: 100%;
}
/* anything using .*-img-card-container is unused
.redesign .rect-img-card-container + .exclusion-btn {
  margin-top: 0;
} */

/* ------------------ BUTTON UTILITIES ------------------ */
.redesign .centered-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* ------------------ BUTTON RESPONSIVENESS ------------------ */

@media screen and (min-width: 376px) and (max-width: 743px) {
  .redesign .chrome-btn.chrome-btn-lg {
    width: 48px;
    height: 48px;
  }
  .redesign .chrome-btn.chrome-btn-lg.float-bottom-right {
    top: calc(100dvh - 68px);
  }
}

.clickable-text {
  cursor: pointer;
}

.update-action {
  color: var(--evergreen);
}

.warning-action {
  color: var(--salmon-red);
}
