/* button color varies depending on context / theme or background color */

/* baseline button overrides */
button {
  border: none;
  border-radius: 8px;
  padding: 6px;
  margin: 2px;
}

/* Wide Buttons */
.call-to-action {
  border: none;
  border-radius: 8px;
  background-color: var(--base-button-color);
  color: var(--dark-font);
  padding: 10px 20px;
  min-height: 44px;
  width: 160px;
  font-size: 16px; /* TODO: is this really set per-button? */
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  box-shadow: 0px 0px 20px 0px rgba(84, 110, 122, 0.2);
  transition-duration: 0.4s;
  cursor: pointer;
}

.call-to-action:hover {
  background-color: var(--transition-button-color);
}

.call-to-action:active {
  background-color: var(--darkest-button-color);
  color: var(--pure-white);
}

.call-to-action.type-b {
  background-color: var(--pure-white);
}
.call-to-action.type-b:hover {
  background-color: var(--semitranslucent-white);
}
.call-to-action:active {
  background-color: var(--darkest-button-color);
  color: var(--light-font);
}

/* Round Iconic Buttons */
.round-button {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  padding: 6px; /* ... yields 32x32 inner content area */
  background-color: var(--lightest-button-color);
  color: var(--dark-font);
  /* TODO ... */
}
.round-button:hover {
  background-color: var(--transition-button-color);
}
.round-button:active {
  background-color: var(--darkest-button-color);
  color: var(--light-font);
}

.round-button.no-fill {
  background-color: transparent;
}
.round-button.no-fill:hover {
  background-color: var(--translucent-white);
}
.round-button.no-fill:active {
  background-color: var(--pure-white);
  color: var(--dark-font);
}

/* Responsiveness */
@media (min-width: 376px) and (max-width: 743px) {
}

@media (min-width: 744px) and (max-width: 1023px) {
  .call-to-action {
    font-size: 20px;
    min-height: 52px;
    width: 200px;
  }
}

@media (min-width: 1024px) {
  .call-to-action {
    font-size: 22px;
    min-height: 56px;
    width: 220px;
  }
}


.uncertain-button {
  width: 210px;
  height: 44px;
  border: none;
  display: flex;
  align-items: center;
  justify-items: start;
  gap: 1em;
  border-radius: 20px;
  background: rgba(256, 256, 256, 0.75)
}
.uncertain-button img {
  width: 44px;
  height: 44px;
  flex: 0 0;
  margin-left: 8px;
}
.uncertain-button span {
  font-weight: bold;
}