.progress-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: auto;
}
.progress-dots .dot {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: var(--pure-white);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
}
.progress-dots .dot-current {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  color: var(--pure-white);
  background-color: var(--salmon-red);
  text-align: center;
  font-size: 12px;
  line-height: 24px;
}
.header-loop-dots {
  position: absolute;
  width: 100%;
  bottom: 8px;
}
@media screen and (min-width: 744px) {
  .header-loop-dots {
    bottom: 16px;
  }
}