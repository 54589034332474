@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "Poppins", sans-serif;
  color: var(--dark-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

.title {
  color: #222222;
}

.large-title {
  font-size: 24px;
  line-height: 1.4;
  margin-bottom: 8px;
  font-weight: 700;
}

.small-title {
  font-size: 16px;
  margin-top: 20px;
  font-weight: 700;
  line-height: 24px;
}

.body-small {
  margin-bottom: 20px;
  overflow-y: auto;
  max-height: 50vh;
  font-size: 14px;
  line-height: 1.5;
}

.body-medium {
  font-size: 14px;
  line-height: 1.75;
}

.answer-text {
  font-size: 12px;
  line-height: 1.5;
}

.link {
  text-decoration: underline;
}

.text-centered {
  text-align: center;
}

.text-left-aligned {
  text-align: left;
}

/* Responsiveness */
@media (min-width: 376px) and (max-width: 743px) {
  .large-title {
    font-size: 28px;
    line-height: 1.4;
  }

  .small-title {
    font-size: 20px;
  }

  .body-medium {
    font-size: 15px;
  }

  .body-small {
    font-size: 18px;
    line-height: 1.6;
  }

  .answer-text {
    font-size: 14px;
  }
}

@media (min-width: 744px) and (max-width: 1023px) {
  .large-title {
    font-size: 32px;
    line-height: 1.4;
    margin-bottom: 15px;
  }

  .small-title {
    font-size: 24px;
  }

  .body-medium {
    font-size: 16px;
  }

  .body-small {
    font-size: 24px;
    line-height: 1.7;
    margin-bottom: 30px;
  }

  .answer-text {
    font-size: 14px;
  }
}

@media (min-width: 1024px) {
  .large-title {
    font-size: 32px;
    line-height: 1.4;
    margin-bottom: 20px;
  }

  .body-medium {
    font-size: 18px;
  }

  .small-title {
    font-size: 28px;
  }

  .body-small {
    font-size: 24px;
    line-height: 1.8;
    margin-bottom: 45px;
  }

  .answer-text {
    font-size: 16px;
  }
}

/* 
StackOverflow solution to manually set the Unicode range, but this requires a
direct link to the Google Font so it's a little more iffy. Let's see if actually
needed -- the @import URL already specifies some unicode-range values after all.

@font-face {
  font-family: NotoColorEmojiLimited;
  unicode-range: U+1F1E6-1F1FF;
  src: url(https://raw.githack.com/googlefonts/noto-emoji/main/fonts/NotoColorEmoji.ttf);
}
  */
.emoji-support {
  font-family: AppleColorEmoji, "Noto Color Emoji", sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.5;
}
